<template>
  <div>
    <TimeLine ref="timeline" :contactId="id"></TimeLine>
    <AddModal
      :contactId="id"
      :visiable="addShow"
      @closeModal="addModalClose"
    ></AddModal>
  </div>
</template>

<script>
export default {
  name: 'tiemPage',
};
</script>
<script setup>
import { ref, defineExpose } from 'vue';
import { useRoute } from 'vue-router';
import AddModal from '@/views/timeline/components/addModal.vue';
import TimeLine from '@/views/timeline/components/timeline.vue';

const route = useRoute();
const { id } = route.params;

const addShow = ref(false);
const timeline = ref();

const addModalShow = e => {
  addShow.value = true;
};

const addModalClose = e => {
  addShow.value = false;
  if (e) {
    // infoData()
    console.log('数据刷新');
    timeline.value.initData();
  }
};

defineExpose({ addModalShow });
</script>

<style scoped></style>
